<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="$router.back()">系统设置</a>
          <i>></i>
          <a href="javascript:;" class="cur-a"
            >{{ stuJson.stu == "add" ? "新增" : "修改" }}推广图设置</a
          >
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="bannerList">
              <div class="FormBox">
                <div class="form-box">
                  <el-form
                    ref="baseform"
                    label-width="7rem"
                    class="form"
                    :rules="rules"
                    :model="baseform"
                  >
                    <el-form-item label="轮播图名称" prop="rotationName">
                      <el-input
                        v-model="baseform.rotationName"
                        size="small"
                        maxlength="20"
                        show-word-limit
                      ></el-input>
                      <!-- <p v-show='baseform.rotationName.length==20' style="color:#f46173">不能超过20个字</p> -->
                    </el-form-item>
                    <el-form-item label="发布系统" prop="terminal">
                      <!-- <el-checkbox-group v-model="baseform.terminals">
                        <el-checkbox label="2">小程序</el-checkbox>
                        <el-checkbox label="1">APP</el-checkbox>
                        <el-checkbox label="3">PC端</el-checkbox>
                      </el-checkbox-group> -->
                      <el-radio-group
                        v-model="baseform.terminal"
                        @change="terminalsChange"
                      >
                        <el-radio label="2">小程序</el-radio>
                        <el-radio label="1">安知App</el-radio>
                        <el-radio label="3">安知Web</el-radio>
                      </el-radio-group>
                      <!-- 小程序下内容 -->
                      <el-form-item prop="rotationPositionId" >
                        <el-radio-group
                          v-model="baseform.rotationPositionId"
                        >
                          <el-radio :label="item.rotationPositionId" v-for="(item, index) in sysRotationPositionData" :key="index">{{item.rotationPositionName}}</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </el-form-item>
                    <el-form-item label="行政区划">
                      <el-cascader
                        v-model="baseform.areaId"
                        :options="areatreeList"
                        :props="propsarea"
                        placeholder="请选择行政区划"
                        clearable
                        filterable
                        size="small"
                      ></el-cascader>
                      <!-- <el-cascader :options="areatreeList" :props="{ multiple: true }" collapse-tags clearable  placeholder="请选择行政区划"></el-cascader> -->
                      <p class="font-remind">
                        当行政区划为空时，这张轮播图为默认，所有的行政区划都会显示
                      </p>
                    </el-form-item>
                    <!-- <el-form-item label="背景颜色" prop='backgroundColor'>
                      <el-color-picker v-model="baseform.backgroundColor"></el-color-picker>
                    </el-form-item>-->
                    <el-form-item label="轮播图顺序" prop="sort">
                      <el-input-number
                        style="width: 100%"
                        size="small"
                        :min="1"
                        v-model="baseform.sort"
                        @change="handleChange"
                      ></el-input-number>
                    </el-form-item>
                    <el-form-item label="轮播图状态" prop="state">
                      <el-select
                        v-model="baseform.state"
                        placeholder="请选择"
                        size="small"
                      >
                        <el-option
                          v-for="item in bannerStateList"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item
                      label="上传轮播图"
                      prop="thumbnail"
                      :class="[rotationId ? 'item-disabled' : '']"
                      required
                    >
                      <el-upload
                        :on-change="handleAvatarSuccess2"
                        :before-upload="$beforeAvatarUpload"
                        :http-request="$requestMine"
                        :show-file-list="false"
                        class="img-el-upload"
                        action
                        accept="image/png, image/gif, image/jpg, image/jpeg"
                      >
                        <el-image
                          :src="
                            baseform.thumbnail ||
                            require('@/assets/develop.png')
                          "
                          fit="contain"
                          class="imgCenter"
                        ></el-image>
                      </el-upload>
                      <p>注意:各端banner尺寸建议(宽*高)</p>
                      <p style="color: #f46173">1.安知培训建议尺寸750*240</p>
                      <p style="color: #f46173">2.安卓/IOS/安知课堂建议尺寸750*284</p>
                      <p style="color: #f46173">3.内蒙工伤预防首页小程序建议尺寸750*240</p>
                      <p style="color: #f46173">4.内蒙古工伤预防学习页小程序建议尺寸750*240</p>
                      <p style="color: #f46173">5.北京晟融官网建议尺寸2560*1000</p>
                      <p style="color: #f46173">6.安知平台官网建议尺寸2560*527</p>
                      <p style="color: #f46173">7.内蒙古工伤预防个人学习端建议尺寸2560*400</p>
                    </el-form-item>
                    <el-form-item label="跳转目标" prop="rotationType">
                      <el-radio-group
                        v-model="baseform.rotationType"
                        @change="rotationTypeChange"
                      >
                        <el-radio :label="1">链接</el-radio>
                        <el-radio :label="0" v-if="baseform.terminal=='1'">应用</el-radio>
                        <el-radio :label="2" v-if="baseform.terminal!='3'">小程序</el-radio>
                      </el-radio-group>
                    </el-form-item>
                    <el-form-item
                      prop="rotationPath"
                      v-if="baseform.rotationType == 1"
                    >
                      <el-input
                        v-model="baseform.rotationPath"
                        size="small"
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      prop="rotationPath"
                      v-if="baseform.rotationType == 0"
                    >
                      <el-select
                        v-model="baseform.rotationPath"
                        placeholder="请选择应用"
                        size="small"
                        clearable
                      >
                        <el-option
                          v-for="item in innerList"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item
                      prop="rotationPath"
                      v-if="baseform.rotationType == 2"
                    >
                      <el-input
                        v-model="baseform.rotationPath"
                        size="small"
                      ></el-input>
                      <!-- <p v-show='baseform.rotationName.length==20' style="color:#f46173">不能超过20个字</p> -->
                    </el-form-item>
                  </el-form>
                </div>
              </div>
              <div class="btn-box">
                <el-button type="primary" @click="doCancel" class="bgc-bv"
                  >取 消</el-button
                >
                <el-button type="primary" class="bgc-bv" @click="doEditSave"
                  >确 定</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "operate/AddPromote",
  components: {},
  data() {
    //验证码校验
    let validateUrl = (rule, value, callback) => {
      if (this.baseform.rotationType == 1) {
        let reg1 =/([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/|[wW]{3}.|[wW][aA][pP].|[fF][tT][pP].|[fF][iI][lL][eE].)[-A-Za-z0-9+&@#\/%?=~_|!:,.;]+[-A-Za-z0-9+&@#\/%=~_|]/
//          let reg =
//           /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/g;
//          let strRegex = "^((https|http|ftp|rtsp|mms)?://)" + "?(([0-9a-z_!~*'().&=+$%-]+: )?[0-9a-z_!~*'().&=+$%-]+@)?"          
//  + "(([0-9]{1,3}\.){3}[0-9]{1,3}" // IP形式的URL- 199.194.52.184
//   + "|" // 允许IP和DOMAIN（域名）
//   + "([0-9a-z_!~*'()-]+\.)*" // 域名- www.
//   + "([0-9a-z][0-9a-z-]{0,61})?[0-9a-z]\." // 二级域名
//   + "[a-z]{2,6})" // first level domain- .com or .museum
//   + "(:[0-9]{1,4})?" // 端口- :80
//   + "((/?)|" // a slash isn't required if there is no file name
//   + "(/[0-9a-z_!~*'().;?:@&=+$,%#-]+)+/?)$";
//    var re = new RegExp(strRegex);
        if (value && !reg1.test(value) ) {
        // if (value && !reg.test(value) && !re.test(value)) {
          callback(new Error("请输入带有http或https的正确链接地址"));
        } else {
          callback();
        }
      } else if (this.baseform.rotationType == 0) {
        if (value == "") {
          callback(new Error("请选择应用"));
        } else {
          callback();
        }
      } else if (this.baseform.rotationType == 2) {
        if (value == "") {
          callback(new Error("请输入跳转小程序链接地址"));
        } else {
          callback();
        }
      }
    };
    return {
      rotationId: "",
      stuJson: {
        stu: this.$route.query.stu,
      },
      areatreeList: [],
      propsarea: {
        //行政规划的值
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      // 轮播图状态
      bannerStateList: [
        {
          value: "10",
          label: "启用",
        },
        {
          value: "20",
          label: "禁用",
        },
      ],
      innerList: [],
      // 基本信息
      baseform: {
        rotationName: "", // 轮播图名称
        terminal: '', //发布系统
        rotationPositionId:'',
        areaId: "",
        // backgroundColor:'#409EFF',//背景颜色默认值
        sort: "", //轮播图顺序
        state: "", // 轮播图状态
        authentication: "", // 专业
        thumbnail: "", //图片地址
        fileKey: "", //上传的图片
        // terminalStr:'',
        // thumbnails:''
        rotationPath: "",
        rotationType: 99,
      },
      rules: {
        rotationName: [
          { required: true, trigger: "blur", message: "请输入轮播图名称" },
        ],
        terminal: [
          { required: true, trigger: "change", message: "请选择发布系统" },
        ],
        sort: [
          { required: true, trigger: "blur", message: "请输入轮播图顺序" },
        ],
        state: [
          { required: true, trigger: "change", message: "请选择轮播图状态" },
        ],
        thumbnail: [
          { required: true, trigger: "change", message: "请上传轮播图" },
        ],
        rotationPath: [{ validator: validateUrl, trigger: "blur" }],
        backgroundColor: [{ required: true }],
      },
      sysRotationPositionData:[],
    };
  },
  created() {
    if (this.$route.query.id) {
      this.rotationId = this.$route.query.id;
      this.getDeil();
    }
    this.getareatree();
    this.getDictionary();
  },
  mounted() {},
  computed: {},
  methods: {
    //发布系统设置change事件
    terminalsChange(sysRotationPositionTerminal) {
      this.$post('/rotationPosition/selectListByTerminal',{sysRotationPositionTerminal}).then(res => {
          // console.log(res)
          if(res.status == '0') {
            this.sysRotationPositionData = res.data
            this.baseform.rotationPositionId = this.stuJson.stu == "add" ? res.data[0].rotationPositionId : this.baseform.rotationPositionId
          }
      })
    },
    rotationTypeChange(val) {
      this.baseform.rotationPath = "";
      this.$refs["baseform"].clearValidate("rotationPath");
    },
    getDictionary() {
      const APP_INNER_PATH_list = this.$setDictionary("APP_INNER_PATH", "list");
      const list = [];
      for (const key in APP_INNER_PATH_list) {
        list.push({
          value: key,
          label: APP_INNER_PATH_list[key],
        });
      }
      this.innerList = list;
    },
    //图片上传
    handleAvatarSuccess2(res) {
      let extension = res.name.substring(
        res.name.lastIndexOf(".") + 1,
        res.name.length
      );
      let formData = new FormData();
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      formData.append("folder ", "LECTURER");
      formData.append("isPublic ", true);
      const that = this;
      that
        .$Postformat("/sys/upload", formData)
        .then((result) => {
          that.baseform.thumbnail = result.data.fileURL || "";
          that.baseform.fileKey = result.data.fileKey || "";
        })
        .catch(() => {
          setTimeout(() => {
            that.$message({
              type: "warning",
              message: "上传失败",
            });
          }, 300);
        });
    },

    //点击编辑获取数据
    getDeil() {
      this.$post("/run/rotation/getRotationInfo", {
        rotationId: this.$route.query.id,
      })
        .then((res) => {
          if (res.status == "0") {
            const baseform = res.data || {};
            this.baseform = {
              ...baseform,
              terminal: baseform.terminal,
              rotationPositionId: baseform.rotationPositionId,
              thumbnail: baseform.picPath,
              fileKey: baseform.pic,
              rotationType: baseform.rotationType,
            };
            this.rotationPath = this.baseform.rotationPath;
            this.terminalsChange(baseform.terminal)
          }

          // this.baseform.terminals = baseform.terminalStr;
          // this.baseform.thumbnail = baseform.picPath;
          // this.baseform.fileKey = baseform.pic;
          // this.baseform.rotationName = baseform.rotationName;
          // this.baseform.areaId = baseform.areaId;
          // this.baseform.sort = baseform.sort;
          // this.baseform.state = baseform.state;
          // this.baseform.rotationPath = baseform.rotationPath;
        })
        .catch(() => {});
    },
    // 编辑保存
    doEditSave() {
      const params = {
        rotationName: this.baseform.rotationName, //轮播图 名称
        areaId: this.baseform.areaId || "", //行政区划
        // backgroundColor: this.baseform.backgroundColor, // 背景颜色
        terminal: this.baseform.terminal, // 发布系统
        rotationPositionId: this.baseform.rotationPositionId, // 发布系统
        sort: this.baseform.sort, // 轮播图顺序
        state: this.baseform.state, // 轮播图状态
        pic: this.baseform.fileKey, //轮播图片
        picPath: this.baseform.thumbnail, //轮播图片地址
        rotationPath: this.baseform.rotationPath, //轮播图片地址
        rotationType: this.baseform.rotationType
      };
      if (this.rotationId) {
        params.rotationId = this.rotationId;
      }
      const baseform = this.$refs["baseform"];
      baseform.validate((valid) => {
        if (valid) {
          this.$post(
            this.rotationId ? "/run/rotation/modify" : "/run/rotation/insert",
            params
          )
            .then((res) => {
              if (res.status == 0) {
                this.$message({
                  type: "success",
                  message: "保存成功",
                });
                this.doCancel();
              }
            })
            .catch((err) => {
              return;
            });
        }
      });
    },
    // 点击取消
    doCancel() {
      this.$router.push({
        path: "/web/operate/Promote",
        query: {
          refresh: true,
        },
      });
    },

    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.bannerList {
  display: flex;
  flex-direction: column;
  height: 100%;
  .FormBox {
    flex: 1;
  }
}
.btn-box {
  padding: 20px 20%;
  display: flex;
  justify-content: center;
  button {
    padding: 12px 30px;
  }
}
</style>

